import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { token, isTokenExpired } from "../utils"; // Import the function to check token expiration

const ProtectedRoute = ({ children }) => {
  const loginToken = token();

  let location = useLocation();

  // Check if the token is expired
  if (!loginToken || isTokenExpired(loginToken)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

export default ProtectedRoute;

