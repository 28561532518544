import axios from "axios";

axios.interceptors.request.use((config) => {
  config.headers["Authorization"] = localStorage.getItem("TOKEN")
  config.headers["DeviceID"] = localStorage.getItem("platformKeyUser");
  config.headers["LangID"] = localStorage.getItem("langId") || 1;
  // config.headers["Content-Type"] = 'application/json';
  config.headers["Environment"] = 'Dev';
  return config;
});

export const axiosGet = (url) => axios.get(url);

export const axiosPost = (url, data) => axios.post(url, data);
