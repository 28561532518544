export const generateUniqueString = () => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 16; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * characters.length)
        );
    }

    return result;
}

export const deviceID = () => {
    const deviceID = localStorage.getItem('platformKeyUser');
    return deviceID;
}

export const userInfo = () => {
    const userInfo = JSON.parse(localStorage.getItem('AUTH'));
    return userInfo
}

export const profileInfo = () => {
    const profileInfo = JSON.parse(localStorage.getItem('USER_PROFILE'));
    return profileInfo
}


export const loginAs = () => {
    const loginAs = sessionStorage.getItem('LOGIN_AS');
    return loginAs;
}

export const getOptions = () => {
    const getOptions = JSON.parse(localStorage.getItem('OPTIONS'));
    return getOptions;
}

export const getSelectedTutor = () => {
    const tutor = JSON.parse(sessionStorage.getItem('SELECTED_TUTOR'));
    return tutor;
}

export const getLangID = () => {
    const langID = localStorage.getItem('langId');
    return langID
}

export const token = () => {
    const token = localStorage.getItem('TOKEN');
    return token;
}

export const role = () => {
    const role = localStorage.getItem('ROLE');
    return +role;
}

export const sessionID = () => {
    const sessionID = localStorage.getItem('SESSION_ID');
    return sessionID;
}

export const isTokenExpired = (token) => {
    // Implement token expiration check here
    // Check if the token has expired based on its expiration time
    const decodedToken = decodeToken(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
};

const decodeToken = (token) => {
    try {
        const tokenPayload = token.split(".")[1];
        const decoded = atob(tokenPayload);
        return JSON.parse(decoded);
    } catch (error) {
        return null;
    }
};

export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Format the time as 'h:mm AM/PM'
    const time = date.toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    // Check if the date is today
    const today = new Date();
    const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

    // Determine whether to display "Today" or the date
    const dateDisplay = isToday ? 'Today' : date.toDateString();

    return `${time}, ${dateDisplay}`;
}

