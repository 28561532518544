import React from 'react';
import './Loading.css';

const Loading = () => {
  return (
    <React.Fragment>
     <div className='homePageSec'>
                <img src='./images/Ellipse1.png' className='ellipseOne' alt="" />
                <div className='homePageContent'>
                    <div className='logoSecHome'>
                    </div>
                    <div className='languagesBtn'>
                        <h3 className="loading">Loading.....</h3>
                    </div>
                </div>
                <img src='./images/Ellipse2.png' className='ellipseTwo' alt="" />
                <img src='./images/Ellipse3.png' className='ellipseThree' alt="" />
            </div>

    </React.Fragment>
  );
}

export default Loading;