import React, { useEffect } from 'react';
import './KnetPayment.css';
// import 'src/index.css'

const KnetPayment = ({ paymentHTML }) => {
    useEffect(() => {
        // Trigger the form submission when the component loads
        document.forms['form'].submit();
    }, []);


    return (
        <React.Fragment>
            <div className='homePageSec'>
                <img src='./images/Ellipse1.png' className='ellipseOne' alt="" />
                <div className='homePageContent'>
                    <div className='logoSecHome'>
                    </div>
                    <div className='languagesBtn'>
                        <h3 className="loading">Please Wait.....</h3>
                    </div>
                </div>
                <img src='./images/Ellipse2.png' className='ellipseTwo' alt="" />
                <img src='./images/Ellipse3.png' className='ellipseThree' alt="" />
            </div>
            <div dangerouslySetInnerHTML={{ __html: paymentHTML }} />
        </React.Fragment>
    );
};

export default KnetPayment;