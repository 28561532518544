import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCQw_T-au1UePfgIVpET77S5Acuxgt-wHQ",
  authDomain: "app.bezooz.com",
  databaseURL: "https://jasem-2023-default-rtdb.firebaseio.com",
  projectId: "jasem-2023",
  storageBucket: "jasem-2023.appspot.com",
  messagingSenderId: "628227111774",
  appId: "1:628227111774:web:6f3e8ccb848c54b8402dcf",
  measurementId: "G-L25T1RZGLW"
};

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);

// Add a function to refresh the token manually
export const refreshAuthToken = () => {
  const user = auth.currentUser;

  if (user) {
    // User is signed in, get the user's ID token and refresh it
    user.getIdToken(/* forceRefresh */ true)
      .then((refreshedToken) => {
        // Use the refreshed token for authentication
        localStorage.setItem('TOKEN', refreshedToken);
      })
      .catch((error) => {
        console.error('Error refreshing token:', error);
      });
  }
};

// Add a sign-out function
export const signOutUser = async () => {
  return auth.signOut()
    .then(() => {
      console.log('User signed out successfully');
      return true;
    })
    .catch((error) => {
      console.error('Error during sign-out:', error);
      return false;
    });
};

// Set up an interval to check and refresh the token every 10 minute (or any suitable interval)
const refreshInterval = 10 * 60 * 1000; // 10 minutes in milliseconds

// Check if the user is signed in and then refresh the token
setInterval(() => {
  const user = auth.currentUser;
  if (user) {
    refreshAuthToken();
  }
}, refreshInterval);

export default firebaseApp;


